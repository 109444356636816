import React from "react";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import Container from "../../styling/container";
import {
  Layout,
  Wrapper,
  Article,
  Title,
  List,
  ListItem,
  Link,
  LinkIcon,
  Chevron,
} from "./footer.styles";

const Footer = () => (
  <Layout>
    <Container>
      <Wrapper>
        <Article>
          <Title>Nos horaires d'ouverture</Title>
          <List>
            <ListItem>
              Mercredi <Chevron /> Samedi : 11h30 – 14h30
            </ListItem>
            <ListItem>
              Mercredi <Chevron /> Samedi : 19h00 – 23h00
            </ListItem>
            <ListItem>
              Premier dimanche du mois :<br />
              11h30 – 16h00 (brunch)
            </ListItem>
            <ListItem>Fermé le Lundi et Mardi</ListItem>
          </List>
        </Article>
        <Article>
          <Title>Adresse et accès</Title>
          <List>
            <ListItem>
              <Link
                href="https://goo.gl/maps/8muEcFNYVeDjiJjy9"
                target="_blank"
              >
                38 Rue de Besançon, 25300 Pontarlier
              </Link>
            </ListItem>
          </List>
        </Article>
        <Article>
          <Article>
            <Title>Suivez-nous</Title>
            <List>
              <ListItem>
                <LinkIcon
                  href="https://www.facebook.com/versionoriginalepontarlier/"
                  aria-label="Lien Facebook"
                >
                  <FaFacebookSquare />
                </LinkIcon>
                <LinkIcon
                  href="https://www.instagram.com/versionoriginalepontarlier/"
                  aria-label="Lien Instagram"
                >
                  <FaInstagram />
                </LinkIcon>
              </ListItem>
            </List>
          </Article>
          <Article>
            <Title>Crédits photos</Title>
            <List>
              <ListItem>
                <Link href="https://www.deuch-photography.com/" target="_blank">
                  Marc Jardot Photographe
                </Link>
              </ListItem>
            </List>
          </Article>
        </Article>
        <Article>
          <Title>Réservez par téléphone</Title>
          <List>
            <ListItem>
              <Link href="tel:+33363564633">03.63.56.46.33</Link>
            </ListItem>
          </List>
        </Article>
      </Wrapper>
    </Container>
  </Layout>
);

export default Footer;
