import React, { useEffect, useState } from "react";
import Container from "../../../../styling/container";
import SectionLayout from "../../../../styling/sectionLayout";
import {
  MenuItem,
  List,
  MenuTitle,
  Subtitle,
  MenuPart,
  MenuPartItem,
  MenuPartItemPrice,
  InfoParagraph,
  MenuItemLayout,
  MenuSelector,
  MenuButton,
  Line,
  Picture,
  ButtonLink,
  Image,
} from "./menu.styles";
import SectionTitle from "../../../../styling/sectionTitle";
import mm1 from "../../../../images/mm1.webp";
import mm2 from "../../../../images/mm2.webp";
import ms1 from "../../../../images/ms1.webp";
import ms2 from "../../../../images/ms2.webp";
import ms3 from "../../../../images/ms3.webp";
import mb1 from "../../../../images/mb1.webp";
import mb2 from "../../../../images/mb2.webp";

enum MenusEnum {
  EVENING = "evening",
  LUNCH = "lunch",
  BRUNCH = "brunch",
  WINES = "wines",
}

const Menu = () => {
  const date = new Date();
  const day = date.getDay();
  const hour = date.getHours();

  const [currentMenu, setCurrentMenu] = useState<MenusEnum>(
    day === 0
      ? MenusEnum.BRUNCH
      : hour > 15
      ? MenusEnum.EVENING
      : MenusEnum.LUNCH
  );

  return (
    <SectionLayout id="menu">
      <Container fullWidth>
        <SectionTitle color="dark">
          <span>Notre carte</span>
        </SectionTitle>
        <Subtitle>
          Vivant au rythme des saisons, la carte est changée toutes les semaines
          et respecte les codes du bistrot haut de gamme avec un rapport qualité
          – prix inégalable.
        </Subtitle>
        <MenuSelector>
          <MenuButton
            onClick={() => setCurrentMenu(MenusEnum.LUNCH)}
            isCurrentSelection={currentMenu === MenusEnum.LUNCH}
          >
            Menu du midi
          </MenuButton>
          <MenuButton
            onClick={() => setCurrentMenu(MenusEnum.EVENING)}
            isCurrentSelection={currentMenu === MenusEnum.EVENING}
          >
            Menu du soir
          </MenuButton>
          <MenuButton
            onClick={() => setCurrentMenu(MenusEnum.BRUNCH)}
            isCurrentSelection={currentMenu === MenusEnum.BRUNCH}
          >
            Brunch
          </MenuButton>
          <MenuButton
            onClick={() => setCurrentMenu(MenusEnum.WINES)}
            isCurrentSelection={currentMenu === MenusEnum.WINES}
          >
            Vins
          </MenuButton>
        </MenuSelector>
        <List>
          {currentMenu === MenusEnum.LUNCH && (
            <MenuItemLayout>
              <MenuItem>
                <MenuTitle>Menus du midi</MenuTitle>
                <MenuPart>
                  <MenuPartItem>
                    Menu unique à<MenuPartItemPrice>25€</MenuPartItemPrice> qui
                    change tous les deux jours.
                  </MenuPartItem>
                  <MenuPartItem>
                    Nous proposons toujours une autre proposition végétarien et
                    proteiné, actuellement : les Yakisoba
                  </MenuPartItem>
                  <MenuPartItem>
                    <ButtonLink
                      href="https://www.instagram.com/versionoriginalepontarlier/"
                      target="_blank"
                    >
                      Voir notre menu sur Instagram
                    </ButtonLink>
                  </MenuPartItem>
                </MenuPart>
                <Line />

                <MenuPart>
                  <Picture>
                    <Image
                      src={mm1}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                    <Image
                      src={mm2}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                  </Picture>
                </MenuPart>
              </MenuItem>
            </MenuItemLayout>
          )}
          {currentMenu === MenusEnum.EVENING && (
            <MenuItemLayout>
              <MenuItem>
                <MenuTitle>Menu du soir</MenuTitle>
                <MenuPart>
                  <MenuPartItem>
                    Carte composée, au choix de 2 pièces apéritives, 3 ou 4
                    entrées, 3 ou 4 plats, 3 desserts changeant au fil des
                    saisons. Notre carte est équilibrée entre les choix viandes,
                    poissons et végétariens.
                  </MenuPartItem>
                  <MenuPartItem>
                    <ButtonLink
                      href="https://www.instagram.com/versionoriginalepontarlier/"
                      target="_blank"
                    >
                      Voir notre menu sur Instagram
                    </ButtonLink>
                  </MenuPartItem>
                </MenuPart>
                <Line />
                <MenuPart>
                  <Picture>
                    <Image
                      src={ms1}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                    <Image
                      src={ms2}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                      moreHeight
                    />
                    <Image
                      src={ms3}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                  </Picture>
                </MenuPart>
              </MenuItem>
            </MenuItemLayout>
          )}
          {currentMenu === MenusEnum.BRUNCH && (
            <MenuItemLayout>
              <MenuItem>
                <MenuTitle>Brunch</MenuTitle>
                <MenuPart>
                  <MenuPartItem>
                    Brunch sur place sous forme de buffet, tous les premiers
                    dimanches du mois, de 11h30 à 14h30. Retrouvez les mets
                    traditionnels des brunchs ainsi que des plats maison en
                    fonction des arrivages locaux et saisonniers.
                  </MenuPartItem>
                  <MenuPartItem>
                    <br />
                  </MenuPartItem>
                  <MenuPartItem>
                    Brunch à volonté hors boisson
                    <MenuPartItemPrice>36€</MenuPartItemPrice>
                  </MenuPartItem>
                  <MenuPartItem>
                    Brunch à volonté avec boisson chaude et jus d’orange pressé
                    <MenuPartItemPrice>40€</MenuPartItemPrice>
                  </MenuPartItem>
                  <MenuPartItem>
                    Brunch pour les enfants de moins de 12 ans
                    <MenuPartItemPrice>15€</MenuPartItemPrice>
                  </MenuPartItem>
                </MenuPart>
                <Line />
                <MenuTitle>Brunch à emporter</MenuTitle>
                <MenuPart>
                  <MenuPartItem>
                    Vous voulez profiter d'un brunch à la maison ? Récupérez
                    votre box entre 10h30 et 12h00, composée de 7 ou 8 pièces
                    salées et 5 ou 6 pièces sucrées.
                    <br />
                    Date variable à retrouver sur les réseaux sociaux.
                  </MenuPartItem>
                  <MenuPartItem>
                    <br />
                  </MenuPartItem>
                  <MenuPartItem>
                    La box à emporter par personne
                    <MenuPartItemPrice>25€</MenuPartItemPrice>
                  </MenuPartItem>
                  <MenuPartItem>
                    La box + jus d’oranges pressées, viennoiseries, pain,
                    confitures maison et pâte à tartiner
                    <MenuPartItemPrice>29€</MenuPartItemPrice>
                  </MenuPartItem>
                </MenuPart>
                <Line />
                <MenuPart>
                  <Picture>
                    <Image
                      src={mb1}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                    <Image
                      src={mb2}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                  </Picture>
                </MenuPart>
              </MenuItem>
            </MenuItemLayout>
          )}
          {currentMenu === MenusEnum.WINES && (
            <MenuItemLayout>
              <MenuItem>
                <MenuTitle>Carte des vins</MenuTitle>
                <MenuPart>
                  <MenuPartItem>
                    Notre cave s’enrichissant perpétuellement de nouvelles
                    références, nous vous présentons cette carte à titre
                    indicatif.
                  </MenuPartItem>
                  <MenuPartItem>
                    <ButtonLink
                      href={"carte_des_vins.pdf"}
                      target="_blank"
                      download
                    >
                      Voir notre carte des vins
                    </ButtonLink>
                  </MenuPartItem>
                </MenuPart>
                <Line />

                <MenuPart>
                  <Picture>
                    <Image
                      src={ms1}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                    <Image
                      src={mm2}
                      alt={"Cette image vous donnera envie de manger chez nous"}
                      loading="lazy"
                      decoding="async"
                    />
                  </Picture>
                </MenuPart>
              </MenuItem>
            </MenuItemLayout>
          )}
        </List>
        <InfoParagraph>
          Tous nos plats sont fait maison et élaborés à partir de produits
          frais. Nos viandes et poissons sont d'origine France.
          <br />
          Pour la liste des allergènes, veuillez nous consulter. Nos prix sont
          TTC, service compris.
        </InfoParagraph>
      </Container>
    </SectionLayout>
  );
};

export default Menu;
